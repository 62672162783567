<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title>
          Revisión de disponibilidad
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </v-card-title>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
          <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idpregunta)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          No hay información disponible
        </template>
        <template v-slot:no-results>
          No se obtuvieron resultados
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nueva solicitud</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">                
                <v-col cols="12">
                  <v-autocomplete v-model="forms.new.sitio" :items="catalogues.sitios" item-text="nombre" item-value="idsitio" label="Sitio" :rules="rules.sitio" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.new.producto" :items="catalogues.productos" item-text="nombre" item-value="idproducto" label="Producto" :rules="rules.producto" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menuScheduleDate" v-model="menuDate" :close-on-content-click="false" :return-value.sync="forms.new.fecha_programado"
                  transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="forms.new.fecha_programado" label="Fecha programado" :rules="rules.fecha_programado" v-bind="attrs" v-on="on" :disabled="loading" dense outlined readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.new.fecha_programado" locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="menuDate = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="success" @click="$refs.menuScheduleDate.save(forms.new.fecha_programado)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menuScheduleHour" v-model="menuHour" :close-on-content-click="false" :return-value.sync="forms.new.hora_programado"
                  transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="forms.new.hora_programado" label="Hora programado" :rules="rules.hora_programado" v-bind="attrs" v-on="on" :disabled="loading" dense outlined readonly></v-text-field>
                    </template>
                    <v-time-picker v-model="forms.new.hora_programado" locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="menuHour = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="success" @click="$refs.menuScheduleHour.save(forms.new.hora_programado)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.cantidad" label="Cantidad" :rules="rules.cantidad" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.frecuencia" label="Frecuencia" :rules="rules.frecuencia" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar solicitud</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">                
                <v-col cols="12">
                  <v-autocomplete v-model="forms.update.sitio" :items="catalogues.sitios" item-text="nombre" item-value="idsitio" label="Sitio" :rules="rules.sitio" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="forms.update.producto" :items="catalogues.productos" item-text="nombre" item-value="idproducto" label="Producto" :rules="rules.producto" :disabled="loading" dense outlined persistent-hint></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menuScheduleDate" v-model="menuDate" :close-on-content-click="false" :return-value.sync="forms.update.fecha_programado"
                  transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="forms.update.fecha_programado" label="Fecha programado" :rules="rules.fecha_programado" v-bind="attrs" v-on="on" :disabled="loading" dense outlined readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="forms.update.fecha_programado" locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="menuDate = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="success" @click="$refs.menuScheduleDate.save(forms.update.fecha_programado)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menuScheduleHour" v-model="menuHour" :close-on-content-click="false" :return-value.sync="forms.update.hora_programado"
                  transition="scale-transition" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="forms.update.hora_programado" label="Hora programado" :rules="rules.hora_programado" v-bind="attrs" v-on="on" :disabled="loading" dense outlined readonly></v-text-field>
                    </template>
                    <v-time-picker v-model="forms.update.hora_programado" locale="es">
                      <v-spacer></v-spacer>
                      <v-btn text color="blue-grey" @click="menuHour = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="success" @click="$refs.menuScheduleHour.save(forms.update.hora_programado)">
                        Aceptar
                      </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.cantidad" label="Cantidad" :rules="rules.cantidad" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.frecuencia" label="Frecuencia" :rules="rules.frecuencia" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la sugerencia?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'

export default {
  name: 'order',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [      
      { text: 'Sitio', align: 'left', sortable: false, value: 'sitio.nombre' },
      { text: 'Producto', align: 'left', sortable: false, value: 'producto.codigo' },
      { text: 'Fecha programado', align: 'left', sortable: false, value: 'fecha_programado' },
      { text: 'Hora programado', align: 'left', sortable: false, value: 'hora_programado' },
      { text: 'Cantidad', align: 'left', sortable: false, value: 'cantidad' },
      { text: 'Frecuencia', align: 'left', sortable: false, value: 'frecuencia' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' },
    ],
    items: [],
    selected: [],
    deleted: null,
    catalogues: {
      sitios: [],
      productos: []
    },
    menuDate: false,
    menuHour: false,
    forms: {
      new: {
        sitio: '',
        producto: '',
        fecha_programado: '',
        hora_programado: '',
        cantidad:'',
        frecuencia:''
      },
      update: {
        idpregunta: '',        
        sitio: '',
        producto: '',
        fecha_programado: '',
        hora_programado: '',
        cantidad:'',
        frecuencia:''
      }
    },
    rules: {      
      sitio: [
        v => !!v || 'El campo es requerido'
      ],
      producto: [
        v => !!v || 'El campo es requerido'
      ],
      fecha_programado: [
        v => /^\d{4}\-\d{2}\-\d{2}$/.test(v) || 'El campo es inválido'
      ],
      hora_programado: [
        v => /^\d{2}\:\d{2}$/.test(v) || 'El campo es inválido'
      ],
      cantidad: [
        v => !!v || 'El campo es requerido',
        v => (/^\d{1,10}$/.test(v) && parseInt(v) >= 0 && parseInt(v) <= 9999999999) || 'El campo es inválido'
      ],
      frecuencia: [
        v => !!v || 'El campo es requerido',
        v => (/^\d{1,10}$/.test(v) && parseInt(v) >= 0 && parseInt(v) <= 9999999999) || 'El campo es inválido'
      ],
    },
    dialogs: {
      new: false,
      update: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      const site = await this.axios.get(services.routes.site)
      if (site.data.data) {
        this.catalogues.sitios = site.data.data
      }
      const product = await this.axios.get(services.routes.product)
      if (product.data.data) {
        this.catalogues.productos = product.data.data
      }
      this.axios.get(services.routes.inquiry)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          this.forms.new[key] = ''
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          this.forms.update[key] = ''
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        this.axios.post(services.routes.inquiry, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Solicitud creada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data) {
      let hora = data.hora_programado.split(':')
      this.forms.update = {
        idpregunta: data.idpregunta,        
        sitio: data.sitio.idsitio,
        producto: data.producto.idproducto,
        fecha_programado: data.fecha_programado,
        hora_programado: hora[0] + ':' + hora[1],
        cantidad: data.cantidad,
        frecuencia: data.frecuencia
      }
      this.dialogs.update = true
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.inquiry, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Solicitud actualizada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        this.axios.delete(services.routes.inquiry, { data: { data: { idpregunta: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Solicitud eliminada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    }
  }
}
</script>

<style scoped>
</style>
